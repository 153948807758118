import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import RelatedRealisations from '../modules/related-realisations'
import ExpertsTeam from '../animations/experts-team'

export default class PageExpertises extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      experts: viewStorage.current.querySelector('.Experts'),
      realisations: viewStorage.current.querySelector('.RelatedRealisations')
    }

    this.init()

  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { experts, realisations } = this.DOM

    if (experts) this.expertsTeam = new ExpertsTeam(experts)
    if (realisations) this.realisations = new RelatedRealisations(realisations)
  }

  onLeaveCompleted() {
    const { expertsTeam, realisations } = this

    if (expertsTeam) expertsTeam.destroy()
    if (realisations) realisations.destroy()
  }
}
