/*
    Load Plugins / Functions
-------------------------------------------------- */
import { Core } from '@unseenco/taxi'
import { globalStorage } from './_globals'
import { onLeave, onEnter, onEnterCompleted } from './_events'

/* --- Renderers --- */
import Page from './pages/page'
import PageHome from './pages/page-home'
import PageExpertises from './pages/page-expertises'
import PageAccompaniment from './pages/page-accompaniment'
import PageAgency from './pages/page-agency'
import PageContact from './pages/page-contact'
import SingleRealisation from './pages/single-realisation'
import ArchiveRealisations from './pages/archive-realisations'

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'
import PageTransitionNav from './transitions/page-transition-nav'

/*
    Setup Core
-------------------------------------------------- */
globalStorage.taxi = new Core({
  renderers: {
    default: Page,
    pageHome: PageHome,
    pageExpertises: PageExpertises,
    pageAccompaniment: PageAccompaniment,
    pageAgency: PageAgency,
    pageContact: PageContact,
    singleRealisation: SingleRealisation,
    archiveRealisations: ArchiveRealisations
  },
  transitions: {
    default: PageTransitionDefault,
    nav: PageTransitionNav
  }
})

/*
    Global Events
-------------------------------------------------- */

globalStorage.taxi.on('NAVIGATE_IN', ({ to, trigger }) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({ from, trigger }) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({ to, from, trigger }) => onEnterCompleted(to, from, trigger))
