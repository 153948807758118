import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class PageHeaderHome {
  constructor(container) {
    this.DOM = { container }

    if (!this.DOM.container) return

    this.DOM.title1 = this.DOM.container.querySelector('h1')
    this.DOM.title2 = this.DOM.container.querySelector('h2')
    this.DOM.visual = this.DOM.container.querySelector('.visual__container')

    this.setParallax()
  }

  setParallax() {
    const { container, title1, title2, visual } = this.DOM

    this.tl = gsap
      .timeline({
        scrollTrigger: {
          trigger: container,
          start: 'top top',
          end: 'bottom top',
          scrub: 1,
          // markers: true
        }
      })
      .to(title1, { yPercent: 100 }, 0)
      .to(title1.querySelector('strong'), { rotate: -180 }, { rotate: 180 }, 0)
      .to(visual, { scale: 1.2 }, 0)
      .to(title2, { yPercent: -30 }, 0)
      .to(title2.querySelector('strong'), { rotate: 180 }, { rotate: -180 }, 0)
  }

  destroy() {

  }
}
