import { Renderer } from '@unseenco/taxi';
import { domStorage, isDEVMODE, viewStorage } from '../_globals'
import ValuesTabs from '../modules/values-tabs'
import TeamStrength from '../animations/team-strength'

export default class PageAgency extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      valuesWrapper: viewStorage.current.querySelector('.Values .wrapper'),
      teamStrength: viewStorage.current.querySelector('.TeamStrength')
    }

    this.init()

  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { valuesWrapper, teamStrength } = this.DOM

    if (valuesWrapper) this.valuesTabs = new ValuesTabs(valuesWrapper)
    if (teamStrength) this.teamStrength = new TeamStrength(teamStrength)

  }

  onLeaveCompleted() {
    const { valuesTabs, teamStrength } = this

    if (valuesTabs) valuesTabs.destroy()
    if (teamStrength) teamStrength.destroy()
  }
}
