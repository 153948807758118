import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class ExpertsTeam {
  constructor(container) {
    this.DOM = { container }
    this.DOM.gallery = this.DOM.container.querySelector('.gallery')
    this.DOM.visuals = this.DOM.container.querySelectorAll('.visual__container')

    if (!this.DOM.visuals.length) return

    this.tweens = []

    this.setAnimation()

  }

  setAnimation() {
    const { visuals, gallery } = this.DOM

    const totalHeight = gallery.offsetHeight

    visuals.forEach((picture, i) => {
      const pictureHeight = picture.offsetHeight
      const pictureOffset = picture.offsetTop
      const pictureDistance = pictureHeight + pictureOffset

      gsap
        .timeline({
          defaults: { ease: 'none', duration: 5 },
          repeat: -1
        })
        .to(picture, {
          y: `-=${totalHeight}`,
          modifiers: {
            y: function(y, target) {
              y = parseFloat(y)
              if (y < -pictureDistance) {
                y += totalHeight
              }

              return `${y}px`
            }
          }
        })
    })
  }

  destroy() {
    const { tweens } = this

    if (tweens.length) tweens.forEach(tween => tween.kill())
  }
}
