import { isDEVMODE, domStorage } from '../_globals'
import { Transition } from '@unseenco/taxi'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { pageIntro } from '../animations/page-intro'

// We'll create a <div></div> for page transition
export let pageTransitionDiv

export default class PageTransitionDefault extends Transition {
  onEnter({ to, trigger, done }) {
    if (isDEVMODE) console.log(`Transition Entering Page : ${to.dataset.taxiView}`)

    gsap
      .timeline({
        defaults: { ease: 'power3.inOut', duration: 0.8 },
        onStart: () => {
          // Reset window scroll
          window.scrollTo(0, 0)
          // Updating the ScrollTriggers
          ScrollTrigger.refresh()
        },
        onComplete: () => {
          // Display the new page
          done()
          // Removing body className while animating
          domStorage.body.classList.add('is--animating')
          // Removing the pageTransitionDiv from the DOM
          domStorage.body.removeChild(pageTransitionDiv)
        },
      })
      // .from(to, { y: -20 }, 0)
      .to(pageTransitionDiv, { clipPath: 'inset(0% 0% 100% 0%)', overwrite: true }, 0)
      .add(pageIntro, 0)
  }

  onLeave({ from, trigger, done }) {
    if (isDEVMODE) console.log(`Transition Leaving Page : ${from.dataset.taxiView}`)

    // We need to create a div for page transitions
    // <div class="PageTransition"></div>
    pageTransitionDiv = document.createElement('div')
    pageTransitionDiv.className = 'PageTransition'
    pageTransitionDiv.innerHTML = `
      <div class="Logo">
        <svg width="115" height="48" viewbox="0 0 115 48" fill="none">
          <path d="M24.6454 21.989H21.3123V0H29.4291C31.1834 0 32.6745 0.627487 33.916 1.87571C35.1642 3.11719 35.7917 4.61506 35.7917 6.37607C35.7917 8.13708 35.1642 9.62146 33.916 10.8629C32.6745 12.1044 31.1834 12.7252 29.4291 12.7252H24.6521V21.989H24.6454ZM24.6454 9.3853H29.4223C30.2522 9.3853 30.9674 9.08843 31.5612 8.49468C32.1482 7.90768 32.4383 7.20597 32.4383 6.37607C32.4383 5.54617 32.1482 4.81748 31.5612 4.22372C30.9674 3.62997 30.2522 3.3331 29.4223 3.3331H24.6454V9.3853Z"/>
          <path d="M52.4032 21.989H48.6382L43.8275 12.7252H40.0626V21.989H36.7295V0H44.8463C46.6006 0 48.0917 0.627487 49.3332 1.87571C50.5814 3.11719 51.2089 4.61506 51.2089 6.37607C51.2089 7.66478 50.8446 8.84553 50.1159 9.91158C49.4074 10.9574 48.4831 11.7266 47.336 12.2191L52.4099 21.9958L52.4032 21.989ZM44.8396 9.38531C45.6695 9.38531 46.3847 9.08843 46.9784 8.49468C47.5654 7.90768 47.8556 7.20597 47.8556 6.37607C47.8556 5.54617 47.5654 4.81748 46.9784 4.22373C46.3847 3.62997 45.6695 3.3331 44.8396 3.3331H40.0626V9.38531H44.8396Z"/>
          <path d="M57.3487 21.989H54.0156V0.026947H57.3487V21.989Z"/>
          <path d="M63.9339 0V16.6722L73.751 0H77.6239L64.6694 21.989H60.5806V0H63.9339Z"/>
          <path d="M85.6533 0V21.989H82.3V16.8544H74.8309L71.5855 21.989H67.6384L81.5443 0H85.6466H85.6533ZM76.9428 13.5213H82.3068V5.05363L76.9428 13.5213Z"/>
          <path d="M100.355 0V3.3331H95.6998V21.989H92.3465V3.3331H87.6909V0H100.355Z"/>
          <path d="M113.627 0V3.3331H105.746V9.29759H111.832V12.6307H105.746V18.6559H113.627V21.989H102.406V0H113.627Z"/>
          <path d="M18.148 25.6055V47.0615H14.8824V42.0551H7.59542L4.431 47.0615H0.578369L14.1469 25.6055H18.148ZM9.64656 38.8029H14.8824V30.5444L9.64656 38.8029Z"/>
          <path d="M21.4404 47.0615V25.6055H27.0811C30.0363 25.6055 32.5665 26.658 34.6649 28.7564C36.7565 30.8548 37.7956 33.385 37.7956 36.3402C37.7956 39.2955 36.7497 41.8122 34.6649 43.9105C32.5665 46.0089 30.0363 47.0615 27.0811 47.0615H21.4404ZM24.6791 43.8228H27.0811C29.1389 43.8228 30.9067 43.0874 32.3708 41.6165C33.8282 40.1591 34.5569 38.3981 34.5569 36.3402C34.5569 34.2823 33.8282 32.5078 32.3708 31.0504C30.9 29.5795 29.1389 28.8441 27.0811 28.8441H24.6791V43.8228Z"/>
          <path d="M38.9155 47.0615V25.6055H44.5561C47.5114 25.6055 50.0416 26.658 52.14 28.7564C54.2316 30.8548 55.2706 33.385 55.2706 36.3402C55.2706 39.2955 54.2248 41.8122 52.14 43.9105C50.0416 46.0089 47.5114 47.0615 44.5561 47.0615H38.9155ZM42.1542 43.8228H44.5561C46.614 43.8228 48.3818 43.0874 49.8459 41.6165C51.3101 40.1456 52.032 38.3981 52.032 36.3402C52.032 34.2823 51.3033 32.5078 49.8459 31.0504C48.375 29.5795 46.614 28.8441 44.5561 28.8441H42.1542V43.8228Z"/>
          <path d="M72.1995 47.0615H68.529L63.833 38.0203H60.1625V47.0615H56.9104V25.6055H64.8248C66.5319 25.6055 67.996 26.2127 69.2037 27.4339C70.425 28.6417 71.0322 30.1058 71.0322 31.8264C71.0322 33.0881 70.6746 34.2351 69.9662 35.2742C69.2779 36.293 68.3738 37.0419 67.2538 37.521L72.1995 47.0547V47.0615ZM64.8248 34.7681C65.6345 34.7681 66.3294 34.478 66.9097 33.8977C67.4765 33.331 67.7666 32.636 67.7666 31.8264C67.7666 31.0167 67.4832 30.3082 66.9097 29.728C66.3294 29.1477 65.6345 28.8576 64.8248 28.8576H60.1625V34.7681H64.8248Z"/>
          <path d="M84.0677 25.6058V28.8579H76.3759V34.674H82.3134V37.9261H76.3759V43.8029H84.0677V47.055H73.1238V25.599H84.0677V25.6058Z"/>
          <path d="M91.4626 47.2568C90.0457 47.1961 88.7368 46.8183 87.5358 46.1166C86.1796 45.3272 85.2485 44.2678 84.7627 42.9387L87.8192 41.8119C88.0688 42.4731 88.5816 43.0061 89.3643 43.4177C90.0525 43.7686 90.8014 43.9642 91.6111 44.0047C92.8593 44.0519 93.8916 43.7483 94.7148 43.0871C95.403 42.5271 95.7673 41.8861 95.8078 41.1709C95.8685 39.8012 94.4314 38.7082 91.5031 37.885C86.915 36.5963 84.6885 34.4102 84.8302 31.3335C84.9179 29.1744 85.8422 27.5619 87.6033 26.4891C88.9459 25.6727 90.5518 25.3016 92.4342 25.396C94.7148 25.4972 96.5432 26.2934 97.9197 27.7778L95.5379 29.9976C94.7552 29.1609 93.6757 28.7089 92.2858 28.6482C91.3074 28.6077 90.4708 28.7359 89.7826 29.026C88.703 29.4848 88.1363 30.308 88.089 31.482C88.0283 32.8314 89.4655 33.9177 92.3937 34.7341C95.0319 35.4763 96.9008 36.5626 98.0006 37.9997C98.7631 38.9983 99.1139 40.1048 99.0667 41.3126C98.9857 43.0129 98.2165 44.45 96.7592 45.6308C95.403 46.7171 93.7702 47.2636 91.8742 47.2636C91.7325 47.2636 91.5976 47.2636 91.4694 47.2501L91.4626 47.2568Z"/>
          <path d="M106.448 47.2568C105.031 47.1961 103.722 46.8183 102.521 46.1166C101.165 45.3272 100.234 44.2678 99.748 42.9387L102.805 41.8119C103.054 42.4731 103.567 43.0061 104.35 43.4177C105.038 43.7686 105.787 43.9642 106.596 44.0047C107.845 44.0519 108.877 43.7483 109.7 43.0871C110.388 42.5271 110.753 41.8861 110.793 41.1709C110.854 39.8012 109.417 38.7082 106.488 37.885C101.9 36.5963 99.6738 34.4102 99.8155 31.3335C99.9032 29.1744 100.828 27.5619 102.589 26.4891C103.931 25.6727 105.537 25.3016 107.42 25.396C109.7 25.4972 111.529 26.2934 112.905 27.7778L110.523 29.9976C109.741 29.1609 108.661 28.7089 107.271 28.6482C106.293 28.6077 105.456 28.7359 104.768 29.026C103.688 29.4848 103.122 30.308 103.074 31.482C103.014 32.8314 104.451 33.9177 107.379 34.7341C110.017 35.4763 111.886 36.5626 112.986 37.9997C113.748 38.9983 114.099 40.1048 114.052 41.3126C113.971 43.0129 113.202 44.45 111.745 45.6308C110.388 46.7171 108.756 47.2636 106.86 47.2636C106.718 47.2636 106.583 47.2636 106.455 47.2501L106.448 47.2568Z"/>
        </svg>
      </div>
    `

    gsap
      .timeline({
        delay: 0.2,
        defaults: { ease: 'power3.inOut', duration: 0.8 },
        onStart: () => {
          // We append the pageTransitionDiv to the body
          document.body.appendChild(pageTransitionDiv)
        },
        onComplete: () => { 
          // Remove the last page
          done()
        }
      })
      .to(from, { y: 20 }, 0)
      .fromTo(pageTransitionDiv, { clipPath: 'inset(100% 0% 0% 0%)' }, { clipPath: 'inset(0% 0% 0% 0%)' }, 0)
      .fromTo(pageTransitionDiv.children[0], { opacity: 0, scale: 1.1 }, { opacity: 1, scale: 1 }, 0.3)
  }
}
