import { isDEVMODE } from '../_globals'
import Swiper, { Navigation, Pagination, Scrollbar, EffectFade, Autoplay } from 'swiper'
Swiper.use([Navigation, Pagination, Scrollbar, EffectFade, Autoplay])

export default class WknSwiper {

  constructor(el, options) {

    this.DOM = {
      el: el,
      navigation: el.querySelector('.swiper-navigation'),
      pagination: el.querySelector('.swiper-pagination'),
      scrollbar: el.querySelector('.swiper-scrollbar')
    }
    this.options = options

    this.init()

  }

  init() {

    if (isDEVMODE) console.log('New WknSwiper')

    this.createSwiper()
    this.addEvents()

  }

  createSwiper() {

    const { el, navigation, pagination, scrollbar } = this.DOM
    const { options } = this

    // Default Navigation
    let navigationOptions = false
    if (navigation) {
      navigationOptions = {
        nextEl: navigation.querySelector('.next'),
        prevEl: navigation.querySelector('.prev')
      }
    }

    // Default Pagination
    let paginationOptions = false
    if (pagination) {
      paginationOptions = {
        el: pagination,
        type: 'bullets',
        clickable: true
      }
    }

    // Default Scrollbar
    let scrollbarOptions = false
    if (scrollbar) {
      scrollbarOptions = {
        el: scrollbar,
        draggable: true
      }
    }

    this.swiper = new Swiper(el, {
      speed: 600,
      spaceBetween: 40,
      slidesPerView: 1,
      longSwipesRatio: 0.1,
      navigation: navigationOptions,
      pagination: paginationOptions,
      scrollbar: scrollbarOptions,
      ...options
    })

  }

  addEvents() {

    const { swiper } = this

    this.onStartDrag = this.onStartDrag.bind(this)
    swiper.on('touchStart', this.onStartDrag)

    this.onLeaveDrag = this.onLeaveDrag.bind(this)
    swiper.on('touchEnd', this.onLeaveDrag)
  }

  onStartDrag(swiper) {
    swiper.el.classList.add('is--dragging')
  }

  onLeaveDrag(swiper) {
    swiper.el.classList.remove('is--dragging')
  }

  destroy() {

    if (isDEVMODE) console.log('Destroy WknSwiper')

    const { swiper } = this

    swiper.off('touchStart', this.onStartDrag)
    swiper.off('touchend', this.onLeaveDrag)
    swiper.destroy()

  }
}
