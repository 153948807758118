import Swiper, { Navigation, Pagination, Scrollbar, EffectFade, Autoplay } from 'swiper'
Swiper.use([Navigation, Pagination, Scrollbar, EffectFade, Autoplay])

export default class Testimonial {
  constructor(container) {
    // Container = Swiper div
    this.DOM = { container }

    if (!this.DOM.container) return

    this.setSwiper()
  }

  setSwiper() {
    const { container } = this.DOM

    this.swiper = new Swiper(container, {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      effect: 'fade',
      autoplay: { 
        delay: 5000, 
        disableOnInteraction: false 
      },
      navigation: {
        prevEl: container.querySelector('.prev'),
        nextEl: container.querySelector('.next')
      }
    })
  }

  destroy() {
    const { swiper } = this

    if (swiper) swiper.destroy()
  }
}
