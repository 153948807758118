/* global gtag */

/*
Load Plugins / Functions
-------------------------------------------------- */
import { isDEVMODE, globalStorage, viewStorage, domStorage } from './_globals'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import GlobalIntro from './animations/global-intro'

import viewScroll from './modules/view-scroll.js'
import viewPrllx from './modules/view-prllx.js'
import viewInView from './modules/view-inview.js'
import viewModals from './modules/view-modal'

const toggleHeaderTheme = () => {
  if (!domStorage.header) return

  if (viewStorage.current.dataset.header === 'dark') {
    domStorage.header.classList.add('--dark')
  } else {
    domStorage.header.classList.remove('--dark')
  }
}

const toggleNewsletterTheme = () => {
  if (!domStorage.newsletter) return

  if (viewStorage.current.dataset.newsletter === 'light') {
    domStorage.newsletter.classList.add('--light')
  } else {
    domStorage.newsletter.classList.remove('--light')
  }
}

/* --- DOMContentLoaded Function --- */
export const onReady = () => {

  const { body } = domStorage

  viewStorage.viewScroll = new viewScroll()
  viewStorage.viewPrllx = new viewPrllx(body)
  viewStorage.viewInView = new viewInView(body)
  viewStorage.viewModals = new viewModals(body)

  ScrollTrigger.refresh()

  // Global Intro
  new GlobalIntro()

  // Header theme
  toggleHeaderTheme()

  // Newsletter theme
  toggleNewsletterTheme()

  if (document.getElementById('Cursor')) {
    if (globalStorage.Cursor) globalStorage.Cursor.addHovers()
    if (globalStorage.Cursor) globalStorage.Cursor.addInvert()
  }
}


/*
 *  NAVIGATE_IN callback
 *
 * onEnter should only contain event bindings and non-
 * DOM related event measurements. Both view containers
 * are still loaded into the DOM during this callback.
 */
export const onEnter = (to, trigger) => {


  let json_data = to.page.getElementById('json_data');
  if (json_data) {
    if (json_data.innerHTML) {
      json_data = JSON.parse(json_data.innerHTML);
      let link_to_other_language_page = document.querySelector(".Nav__languages > a");
      if (typeof link_to_other_language_page != 'undefined')
        if (typeof json_data.urls.current_other_language != 'undefined' && json_data.urls.current_other_language != null) {
          if (typeof json_data.urls.current_other_language.url != 'undefined')
            link_to_other_language_page.href = json_data.urls.current_other_language.url;
        }
    }
  }

  if (isDEVMODE) console.log('onEnter')

  // LoadingClasses
  const { body } = domStorage
  body.classList.remove('is--loading')

  // ScrollTop
  const { hasSmoothScroll } = viewStorage
  window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop)

  // Update Current View
  viewStorage.current = to.renderer.content

  // Add Page Effects
  setTimeout(() => {
    body.style.overflow = ''
    if (hasSmoothScroll) viewStorage.viewScroll = new viewScroll()
    viewStorage.viewPrllx = new viewPrllx()
    viewStorage.viewInView = new viewInView()
    viewStorage.viewModals = new viewModals()
  }, 150)

  // Header theme
  toggleHeaderTheme()

  // Newsletter theme
  toggleNewsletterTheme()

  // Cursor
  if (document.getElementById('Cursor')) {
    if (globalStorage.Cursor) globalStorage.Cursor.addHovers()
    if (globalStorage.Cursor) globalStorage.Cursor.addInvert()
  }
}


/*
 * NAVIGATE_END callback
 *
 * onEnterCompleted should be your primary event callback.
 * The previous view's DOM node has been removed when this
 * event fires.
 */
export const onEnterCompleted = (to, from, trigger) => {

  if (isDEVMODE) console.log('onEnterCompleted')

  const { hasSmoothScroll, viewScroll } = viewStorage
  const { targetLocation } = globalStorage.taxi

  // Anchor
  if (targetLocation.hasHash) {
    const anchorEl = document.querySelector(window.location.hash)
    if (anchorEl) {
      if (hasSmoothScroll) viewScroll.scrollTo(anchorEl, { offset: 0, duration: 1.8 })
      else gsap.to(window, {duration: 1.8, scrollTo: { y: anchorEl, autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })
    }
  }

  // Analytics
  if (globalStorage.tarteaucitron && globalStorage.tarteaucitron.job.includes('gtag') && typeof gtag !== 'undefined') {
    gtag('config', globalStorage.tarteaucitron.user.gtagUa, {
      'page_path': targetLocation.pathname,
      'page_title': to.page.title,
      'page_location': targetLocation.href
    })
  }
}


/*
 * NAVIGATE_OUT callback
 *
 * onLeave is fired when a highway transition has been
 * initiated. This callback is primarily used to unbind
 * events, or modify global settings that might be called
 * in onEnter/onEnterCompleted functions.
 */
export const onLeave = (from, trigger) => {

  if (isDEVMODE) console.log('onLeave')

  // Remove Previous Page Effects
  const { hasInView, hasSticky, hasPrllx, hasModals } = viewStorage
  if (hasInView) viewStorage.viewInView.destroy()
  if (hasSticky) viewStorage.viewSticky.destroy()
  if (hasPrllx) viewStorage.viewPrllx.destroy()
  if (hasModals) viewStorage.viewModals.destroy()

  if (document.getElementById('Cursor')) {
    if (globalStorage.Cursor) globalStorage.Cursor.removeHovers()
    if (globalStorage.Cursor) globalStorage.Cursor.removeInvert()
  }

  // LoadingClasses
  const { body } = domStorage
  body.classList.add('is--loading')
  body.classList.add('is--animating')

  // Scroll
  const { hasSmoothScroll } = viewStorage
  body.style.overflow = 'hidden'
  if (hasSmoothScroll) viewStorage.viewScroll.destroy()

  const { menu } = domStorage
  const { menuOpen } = globalStorage

  // Close Menu
  if (menuOpen) globalStorage.closeMobileMenu()

  // Remove active links
  const { targetLocation } = globalStorage.taxi
  const navLinks = menu.querySelectorAll('.Nav__link')
  navLinks.forEach((link) => {
    if (link.href === targetLocation.raw) link.classList.add('is--active')
    else link.classList.remove('is--active')
  })

}
