import { isDEVMODE, domStorage, viewStorage } from '../_globals'
import Lenis from '@studio-freight/lenis'
import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
gsap.registerPlugin(Observer)

export default class viewScroll {
  constructor() {
    this.DOM = { container:  domStorage.app }

    this.setScroll()

    viewStorage.hasSmoothScroll = true
  }

  setScroll() {
    this.scroll = new Lenis({
      // duration: 0.82,
      easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
      smooth: true,
      direction: 'vertical'
    })

    const raf = (time) => {
      this.scroll.raf(time)
      this.raf = requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)

    viewStorage.viewScroll = this.scroll
  }

  pause() {
    this.scroll.pause()
  }

  destroy() {
    window.cancelAnimationFrame(this.raf);
    this.id = undefined;
    this.scroll.destroy()
    viewStorage.viewScroll = null
  }
}
