import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class TeamStrength {
  constructor(container) {
    this.DOM = { container }
    this.DOM.team = this.DOM.container.querySelector('.team')

    if (!this.DOM.team) return

    this.DOM.teamHead = this.DOM.team.querySelector('.head')
    this.DOM.teamHeadStars = this.DOM.teamHead.querySelectorAll('i')
    this.DOM.teamMembers = this.DOM.team.querySelector('.members')
    this.DOM.teamMembersInner = this.DOM.team.querySelector('.members > .inner')

    this.setHorizontalScroll()
  }

  setHorizontalScroll() {
    console.log('setting HORIZONTAL scroll')

    const { team, teamHead, teamHeadStars, teamMembers, teamMembersInner } = this.DOM

    let width = teamMembersInner.getBoundingClientRect().width

    this.tween = gsap
      .timeline({
        defaults: { ease: 'none' },
        scrollTrigger: {
          trigger: team,
          scrub: true,
          start: 'top top',
          end: 'bottom bottom'
        }
      })
      .to(teamHead, { x: -width/2 }, 0)
      .to(teamHeadStars, { rotate: 500 }, 0)
      .to(teamMembers, { x: -width }, 0)
  }
  
  destroy() {
    const { tween } = this

    if (tween) tween.kill()
  }
}