import gsap from 'gsap'

export default class ValuesTabs {
  constructor(container) {
    this.DOM = { container }

    if (!this.DOM.container) return

    this.DOM.navButtons = this.DOM.container.querySelectorAll('button')
    this.DOM.tabsContents = this.DOM.container.querySelectorAll('.tab')

    this.settings = {
      activeClassName: '--active'
    }

    this.setTabs()
  }

  setTabs() {
    const { navButtons } = this.DOM

    navButtons.forEach(button => button.addEventListener('mouseenter', this.displayTab.bind(this)))
  }

  displayTab(e) {
    e.preventDefault()

    const { navButtons, tabsContents } = this.DOM
    const { activeClassName } = this.settings
    const currentButton = e.currentTarget

    // 1. Removing the active className for all buttons
    navButtons.forEach(button => button.classList.remove(activeClassName))
    // 2. Adding the active className only for the current button
    currentButton.classList.add(activeClassName)
    // 3. Checking if the activeTab dataset from the button is the same as tabContent dataset
    tabsContents.forEach(content => {
      content.classList.remove(activeClassName)
      if (content.dataset.tab === currentButton.dataset.tab) content.classList.add(activeClassName)
    })
  }

  destroy() {
    const { navButtons } = this.DOM

    navButtons.forEach(button => button.removeEventListener('mouseenter', this.displayTab.bind(this)))
  }
}
